<template>
  <div v-if="isshowgrid">
    <va-card title="Interface List" :class="noAuth && va-height">
      <template slot="actions" v-if="noAuth">
        <va-button 
        color="#002c85" 
        icon="entypo entypo-reply" 
        @click="back()">Back</va-button>
      </template>
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        style="max-height: 60vh;">
        <template slot="status" slot-scope="props">
          <va-badge
            v-if="(props.rowData.speed>=1000)"
            color="success"
            :disabled="true"
          >{{ props.rowData.speed }}
          </va-badge>
          <va-badge
            v-if="(props.rowData.speed==0)"
            color="danger"
            :disabled="true"
          >{{ props.rowData.speed }}
          </va-badge>
          <va-badge
            v-if="(props.rowData.speed=='NA')"
            color="#f542a7"
            :disabled="true"
          >{{ props.rowData.speed }}
          </va-badge>
          <va-badge
            v-if="((props.rowData.speed<1000) && (props.rowData.speed!=0))"
            color="#F7A000"
            :disabled="true"
          >{{ props.rowData.speed }}
          </va-badge>
        </template>
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" :disabled="(props.rowData.type == 'Management' || props.rowData.speed < 1000)" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0">
          </va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>

  <div v-else>
    <va-card title="Update Interface" :class="noAuth && va-height">
      <form @submit.prevent="updateInterfaceSetting()">
        <va-input
          v-model="interface_name"
          type="text"
          label="Interface Name *"
          :disabled="true"
        />
        <va-input
          v-model="unicast_ip"
          type="text"
          label="IP *"
          pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
          placeholder="Enter IP (eg:1.3.2.1)"
          :error="!!unicastIPErrors.length"
          :error-messages="unicastIPErrors"
        />
        <va-select
          v-model="unicast_netmask"
          type="text"
          searchable
          :options="netmaskOptions"
          label="Subnet Mask *"
          placeholder="Select Netmask"
          :error="!!unicastNetmaskErrors.length"
          :error-messages="unicastNetmaskErrors"
          noClear
        />
        <va-input
          v-model="unicast_gateway"
          type="text"
          label="Unicast Gateway *"
          pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
          placeholder="Enter Unicast Gateway"
          :error="!!unicastGatewayErrors.length"
          :error-messages="unicastGatewayErrors"
        />
        <va-select
          v-model="type"
          type="text"
          :options="typeOptions"
          label="Interface Type *"
          placeholder="Select type"
          :error="!!TypeErrors.length"
          :error-messages="TypeErrors"
          noClear
        />
        <va-checkbox
          label="Use Default Route"
          v-model="default_route"
        />
        <va-checkbox
          label="Multicast IP"
          v-model="is_multicast"
        />
        <va-checkbox
          label="P2P"
          v-model="p2p"
        />
        <div class="row" v-if="is_multicast">
          <div class="form-group" v-for="(input,k) in inputs" :key="k">
            <input type="text" class="form-control" v-model="input.name">
            <span>
              <i class="fa fa-minus-circle" @click="remove(k)" v-show="k || ( !k && inputs.length > 1)"></i>
              <i class="fa fa-plus-circle" @click="add(k)" v-show="k == inputs.length-1"></i>
            </span>
          </div>
        </div>
        <div v-if="p2p">
          <va-input
            v-model="node_ip"
            type="text"
            label="Node IP *"
            pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
            placeholder="Enter IP (eg:1.3.2.1)"
            :error="!!nodeIPErrors.length"
            :error-messages="nodeIPErrors"
          />
          <va-input
            v-model="p2p_network"
            type="text"
            label="P2P Network *"
            placeholder="Enter P2P (eg:1.2.2.1/2)"
            :error="!!p2pNetworkErrors.length"
            :error-messages="p2pNetworkErrors"
          />
          <va-input
            v-model="local_asn"
            type="number"
            label="Local ASN *"
            placeholder="Enter Your Local ASN"
            :error="!!LocalAsnErrors.length"
            :error-messages="LocalAsnErrors"
          />
          <va-input
            v-model="neighbour_ip"
            type="text"
            label="Neighbour IP *"
            pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
            placeholder="Enter IP (eg:1.3.2.1)"
            :error="!!neighbourIPErrors.length"
            :error-messages="neighbourIPErrors"
          />
          <va-input
            v-if="super_admin"
            v-model="neighbour_asn"
            type="number"
            label="Neighbour ASN *"
            placeholder="Enter Your Neighbour ASN"
            :error="!!neighbourAsnErrors.length"
            :error-messages="neighbourAsnErrors"
          />
        </div>
        <div class="d-flex justify--center mt-3">
          <va-button type="cancel" class="my-0" flat @click="list()">Cancel</va-button>
          <va-button type="submit" class="my-0" >Update</va-button>
        </div>
      </form>
    </va-card>
  </div>
</template>

<script>
import config from '../../i18n/en'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import REGEX from '../../i18n/regex_pattern'
import { debounce } from 'lodash'

Vue.use(vueResource)

export default {
  name: 'interface',
  created () {
    var role = Vue.$cookies.get('roles')
    if(role == 'SUPER_ADMIN'){
      this.super_admin = true
    }
    this.noAuth = JSON.parse(
      Vue.$cookies.get('keyEnable')
    )
    const loader = Vue.$loading.show({ width: 40, height: 40 })
    this.$http.get(config.menu.host + 'network-setting').then(response => {
      loader.hide()
    }, error => {
      loader.hide()
    })
    this.$http.get(config.menu.host + 'network-setting/interface/all')
    .then(responseInterface => {
      this.interfaces = responseInterface.body
      this.isshowgrid = true
      this.refreshInterface()
      this.getAllInterface()
    })
  },
  data () {
    return {
      noAuth:false,
      term: null,
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      interfaces: [],
      isshowgrid: false,
      term: null,
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      type: [],
      unicast_ip: '',
      up_stream: '',
      default_route: false,
      is_multicast: false,
      port: '',
      edge_domain: '',
      unicast_netmask: '',
      unicast_gateway: '',
      interface_name: '',
      starting_ip: '',
      ending_ip: '',
      unicastIPErrors: [],
      subnetErrors: [],
      unicastNetmaskErrors: [],
      unicastGatewayErrors: [],
      upstreamErrors: [],
      edgeDomainErrors: [],
      portErrors: [],
      isCreate: true,
      netmaskOptions: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32'],
      inputs: [
        {
          name: '',
        },
      ],
      p2p: false,
      node_ip: '',
      nodeIPErrors: [],
      p2p_network: '',
      p2pNetworkErrors: [],
      local_asn: '',
      LocalAsnErrors: [],
      neighbour_ip: '',
      neighbourIPErrors: [],
      neighbour_asn: 31898,
      neighbourAsnErrors: [],
      super_admin: false,
      type: '',
      typeOptions: ['Input', 'Output'],
      TypeErrors: []
    }
  },
  watch: {
  },
  computed: {
    fields () {
      return [
        {
          name: 'interface_name',
          title: 'Interface Name',
        },
        {
          name: '__slot:status',
          title: 'Status',
        },
        {
          name: 'unicast_ip',
          title: 'Unicast IP',
        },
        {
          name: 'type',
          title: 'Type',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    filteredData () {
      return search(this.term, this.interfaces)
    },
  },
  methods: {
    add (index) {
      this.inputs.push({ name: '' })
    },
    remove (index) {
      this.inputs.splice(index, 1)
    },
    back(){
      this.$router.push({name : 'login'})
    },
    getAllInterface () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + 'network-setting/interface/all').then(responseInterface => {
        loader.hide()
        this.interfaces = responseInterface.body
      }, error => {
        loader.hide()
      })
    },
    clearError () {
      this.interface_name = ''
      this.type = ''
      this.unicast_ip = ''
      this.unicast_netmask = ''
      this.unicast_gateway = ''
      this.unicastGatewayErrors.length = []
      this.unicastIPErrors.length = []
      this.unicastNetmaskErrors.length = []
      this.TypeErrors.length = []
    },
    list () {
      this.clearError()
      this.isshowgrid = true
      this.getAllInterface()
    },
    edit (row) {
      this.default_route = row.default_route
      this.type = row.type
      this.interface_name = row.interface_name
      this.unicast_ip = row.unicast_ip
      this.unicast_netmask = row.unicast_netmask
      this.unicast_gateway = row.unicast_gateway
      this.multicast_ip = row.multicast_ip
      this.speed = row.speed
      this.status = row.status
      this.id = row.id
      this.user_id = row.user_id
      this.p2p = row.p2p
      this.p2p_network = row.p2p_network
      this.node_ip = row.node_ip
      this.neighbour_asn = row.neighbour_port
      this.neighbour_ip = row.neighbour_ip
      this.local_asn = row.local_asn
      this.isshowgrid = false
    },
    formReady () {
      return (
        !this.unicastGatewayErrors.length &&
        !this.unicastIPErrors.length &&
        !this.unicastNetmaskErrors.length && 
        !this.nodeIPErrors.length && 
        !this.p2pNetworkErrors.length && 
        !this.LocalAsnErrors.length && 
        !this.neighbourIPErrors.length && 
        !this.neighbourAsnErrors.length && 
        !this.TypeErrors.length
      )
    },
    updateInterfaceSetting () {
      var interfaceName = this.interface_name
      this.unicastGatewayErrors = this.unicast_gateway ? [] : ['Unicast Gateway is required']
      this.unicastIPErrors = this.unicast_ip ? [] : ['Unicast IP is required']
      this.unicastNetmaskErrors = this.unicast_netmask ? [] : ['Unicast Netmask is required']
      this.multicastIPErrors = this.multicast_ip_range ? [] : ['Multicast IP Range is required']
      this.TypeErrors = this.type ? [] : ['Interface Type is required']
      if(this.p2p == true){
        this.nodeIPErrors = this.node_ip ? [] : ['Node IP is required']
        this.p2pNetworkErrors = this.p2p_network ? [] : ['P2P Network is required']
        this.LocalAsnErrors = this.local_asn ? [] : ['Local ASN is required']
        this.neighbourIPErrors = this.neighbour_ip ? [] : ['Neighbour IP is required']
        this.neighbourAsnErrors = this.neighbour_asn ? [] :['Neighbour ASN is required']
      }
      if (!REGEX.ipRegex(this.unicast_ip)) { this.unicastIPErrors.push('Invalid IP') }
      if (!REGEX.numberRegex(this.unicast_netmask)) { this.unicastNetmaskErrors.push('Invalid Subnet Mask') }
      if (!REGEX.ipRegex(this.unicast_gateway)) { this.unicastGatewayErrors.push('Invalid Gateway') }
      if (!this.formReady()) return
      
      if (this.is_multicast) {
        var multicast = []
        for (var i = 0; i < this.inputs.length; i++) {
          multicast.push(this.inputs[i].name)
        }
      }

      var payload = {
        type: this.type,
        interface_name: interfaceName,
        unicast_gateway: this.unicast_gateway,
        unicast_ip: this.unicast_ip,
        unicast_netmask: this.unicast_netmask,
      	default_route: this.default_route,
        is_multicast: this.is_multicast,
	      p2p: this.p2p,
        type: this.type
      }

      if (this.is_multicast) {
        payload.multicast_ip = multicast
      } else {
        payload.multicast_ip = []
      }

      if(this.p2p) {
        payload.p2p = this.p2p
        payload.node_ip = this.node_ip
        payload.p2p_network = this.p2p_network
        payload.local_asn = Number(this.local_asn)
        payload.neighbour_ip = this.neighbour_ip
        payload.neighbour_port = Number(this.neighbour_asn)
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + 'network-setting/' + this.id, payload).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({
            text: response.body,
            type: 'success',
          })
        }
        this.list()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({
            text: error.body,
            type: 'error',
          })
        }
      })
    },
    refreshInterface () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'network-setting/refresh/interface').then(response => {
        loader.hide()
        this.list()
      }, () => {
        loader.hide()
      })
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>

<style lang="scss">
  .badge {
    padding: 8px 0.5rem;
  }

  .va-height {
    height: 100vh;
  }
</style>
